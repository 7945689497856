<!--
 * @Author: zhangjiaheng
 * @Date: 2021-01-13 10:23:34
 * @LastEditTime: 2021-08-25 11:25:38
 * @LastEditors: Please set LastEditors
 * @Description: 固定报表 考勤查询 路由地址 /hr/reportAttend
 * @FilePath: \user\src\views\hr\report\index.vue
-->
<template>
  <div class="reportAttend-box">
    <!-- 顶部 -->
    <en-title-card name="考勤查询">
      <div slot="left">
        <en-icon name="fanhui" size="small" class="attendance-list-back-icon" @click.native="backToAttendance"> </en-icon>
      </div>
      <div slot="right" class="attendance-list-right">
        <div class="filter-item" v-show="hideMap.hideSearch">
          <div class="content">
            <div class="label">
              考勤日期
            </div>
            <en-date-picker :pickerOptions="{ shortcuts }" :clearable="false" v-model="dateRange" type="daterange" range-separator="––" start-placeholder="开始时间" end-placeholder="结束时间">
            </en-date-picker>
            <div class="label">
              范围
            </div>
            <en-select
              :multiple="true"
              mode="tree"
              v-model="deptIdShow"
              :data="tree"
              :filterable="true"
              highlight-current
              :props="{ value: 'id', label: 'name', children: 'nodes' }"
              style="width:280px;"
              @change="selectChange"
            >
            </en-select>
            <en-button class="search-button" @click.native="searchAction">查询</en-button>
          </div>
        </div>
        <div class="attendance-list-right-icon">
          <en-icon style="margin: 16px;color:#A9B5C6;cursor: pointer;" size="18px" name="sousuo" @click.native="getShow('hideSearch')"></en-icon>
          <span class="shu"></span>
          <en-image-setting style="margin: 0 20px;" name="iconquanping" size="small" fill="#FFAD2C" @click.native.stop="openFull">
            全屏
          </en-image-setting>
          <en-image-setting style="margin-right:20px;" name="daochu-danchuang" size="small" fill="#26C393" @click.native.stop="dataExport">
            导出
          </en-image-setting>
        </div>
      </div>
    </en-title-card>
    <!-- 顶部 end -->
    <!-- 中心部分 图形 + 表格 -->
    <div class="reportAttend-content" v-en-loading="loading">
      <!-- 图标行 -->
      <div class="r-content-top">
        <div class="r-content-top-l">
          <div class="mod" :class="showType == 'dept' ? 'cur' : ''" @click="modClick('dept')">
            <en-icon style="" size="16px" name="cuzhuangzhuzhuangtu"></en-icon>
            <span>部门</span>
          </div>
          <div class="mod" :class="showType == 'date' ? 'cur' : ''" @click="modClick('date')">
            <en-icon style="" size="16px" name="quxiantu"></en-icon>
            <span>日期</span>
          </div>
          <div class="mod" :class="showType == 'people' ? 'cur' : ''" @click="modClick('people')">
            <en-icon style="" size="16px" name="cuzhuangzhuzhuangtu"></en-icon>
            <span>人员</span>
          </div>
        </div>
        <div class="r-content-top-r">
          <div class="right-btn" v-show="countType == '0'" @click.stop="countTypeChange('1')">
            <en-icon style="" title="按工作日统计" size="18px" name="kaoqinqiandao-shixin"></en-icon>
          </div>
          <en-icon title="按实际统计" @click.native.stop="countTypeChange('0')" v-show="countType == '1'" style="color:#179FDb;" size="20px" name="kaoqinqiandao-shixin"></en-icon>
          <en-icon style="color:#3e90fe;" size="20px" name="gengduo-kaoqin" @click.native="getshowType1"></en-icon>
        </div>
      </div>
      <!-- 图标行 end -->
      <!-- 图形 -->
      <div class="r-content-chart">
        <!-- 4个图形 1个汇总 3个详情的 -->
        <!-- 汇总 -->
        <div class="chart-box " ref="dom1" :class="showType1 ? 'cur' : ''"></div>
        <!-- 部门 -->
        <div class="chart-box " ref="dom2" :class="!showType1 && showType == 'dept' ? 'cur' : ''"></div>
        <!-- 日期 -->
        <div class="chart-box " ref="dom3" :class="!showType1 && showType == 'date' ? 'cur' : ''"></div>
        <!-- 人员 -->
        <div class="chart-box " ref="dom4" :class="!showType1 && showType == 'people' ? 'cur' : ''"></div>
        <!-- 汇总的 百分百 定位 -->
        <!-- <div class="main-num" v-show="showType1">
          <p class="num-txts">{{ attendance }}</p>
          <p class="tits">出勤率</p>
        </div> -->
      </div>
      <!-- 图形 end -->
      <!-- 汇总行 -->
      <div class="r-content-summary" v-show="hideMap.allData0 && showType1">
        <div class="col lackPerson" v-for="item in summary" :key="item.id" @click.stop="throughFun(item, '')">
          <p>{{ item.num }}</p>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <!-- 汇总行 end -->
      <!-- 表格 -->
      <div class="r-content-table">
        <!-- 2个表格  -->
        <div class="table-box " :class="showType == 'dept' || showType == 'date' ? 'cur' : ''" v-if="deptFlg">
          <!-- 层级表格 不分页 -->
          <en-table
            :height="550"
            :tree-config="{ lazy: true, children: 'nodes', hasChildren: 'hasNode', trigger: 'row', loadMethod: handleTreeLoad }"
            :head-end-config="{ type: 'more', moreChangeMethod: handleMoreChange }"
            :data="deptData"
            :customs="deptHeadList"
            @cell-click="cellClickEvent($event, 'dept')"
          >
            <en-table-column width="60px" fixed="left" type="index"></en-table-column>
            <en-table-column
              width="120px"
              :fixed="index < deptfixedColumnNum ? 'left' : ''"
              v-for="(item, index) in deptHeadList"
              :key="index"
              :prop="item.field"
              :label="item.fieldName"
            ></en-table-column>
          </en-table>
        </div>
        <div class="table-box " :class="showType == 'people' ? 'cur' : ''" v-if="showType == 'people'">
          <!-- 人员表格 分页 -->
          <en-table
            :height="550"
            :head-end-config="{ type: 'more', moreChangeMethod: handleMoreChangepeople }"
            :customs="peopleHeadList"
            :data="peopleData.records"
            :loading="peopleData.loading"
            :page-config="{ pageModel: peopleData, changeMethod: handlePageChanged }"
            @cell-click="cellClickEvent($event, 'people')"
            @filter-sort-change="handleTableFilterChange"
          >
            <en-table-column width="60px" fixed="left" type="index"></en-table-column>
            <en-table-column
              width="120px"
              :fixed="index < peoplefixedColumnNum ? 'left' : ''"
              v-for="(item, index) in peopleHeadList"
              :key="index"
              :prop="item.field"
              :label="item.fieldName"
              :fieldType="item.fieldType"
              :data="item"
              :filter-sort-config="{ filterable: item.filter, sortable: item.sort }"
              :filter-render="{ name: item.fieldType == 1 ? 'ElInput' : 'EnInputRange', attrs: { placeholder: '请输入' + item.fieldName + '...' } }"
            >
            </en-table-column>
          </en-table>
        </div>
      </div>
      <!-- 表格end -->
    </div>
    <!-- 中心部分 图形 + 表格 end -->
    <!-- 穿透 -->
    <attendDetails v-if="attendDetailsFlg" :params="attendDetailsData" :show.sync="attendDetailsFlg" @toShow="toShow"></attendDetails>
    <!-- 全屏 -->
    <!-- 全屏 -->
    <fullScreen v-show="isFullScreen" @close="closeFull" @finish="finishFull">
      <template #content>
        <!-- 多图全屏 -->
        <div v-if="finish" class="full-box">
          <div class="full-title">考勤查询</div>
          <div class="full-center">
            <div class="full-chart">
              <div class="chart-box" style="margin-right:4px;">
                <!-- 汇总图 -->
                <div class="chart-box-center" ref="fulldom1"></div>
                <!-- <div class="main-num">
                  <p class="num-txts">{{ attendance }}</p>
                  <p class="tits">出勤率</p>
                </div> -->
              </div>
              <div class="chart-box" style="margin-left:4px;">
                <!-- 部门柱状 -->
                <div class="chart-box-center" ref="fulldom2"></div>
              </div>
            </div>
            <div class="full-chart">
              <div class="chart-box" style="margin-right:4px;">
                <!-- 部门折线 -->
                <div class="chart-box-center" ref="fulldom3"></div>
              </div>
              <div class="chart-box" style="margin-left:4px;">
                <!-- 人员柱状 -->
                <div class="chart-box-center" ref="fulldom4"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </fullScreen>
  </div>
</template>

<script>
import dayJs from "dayjs";
import Utils from "@/components/en-table-extra/data/utils";
import { hrService } from "@/api";
import fullScreen from "@/components/full-screen";
import attendDetails from "./attendDetails";

export default {
  name: "reportAttend",
  components: { attendDetails, fullScreen },
  data() {
    return {
      templateId: "", // 报表id
      dateRange: [new Date(), new Date()], // 控件值
      day: "", // 查询值 日期
      tree: [],
      hideMap: {
        hideSearch: true, // 查询框是否显示
        allData0: true, // 汇总行的值全为0不显示
        deptData: true
      },
      // 部门参数 显示用 点击了查询 才是真参数
      deptIdShow: [],
      // 部门参数
      deptId: [],
      // 当前显示的图标类型
      showType: "dept",
      // 当前显示的图标类型 汇总 true summary / detailed明细 false
      showType1: true,
      // 部门表头
      deptHeadList: [
        {
          field: "text",
          fieldName: "部门",
          visible: true
        },
        {
          field: "must",
          fieldName: "应出勤（人）",
          visible: true
        },
        {
          field: "actual",
          fieldName: "实出勤（人）",
          visible: true
        },
        {
          field: "lackPerson",
          fieldName: "缺勤（人）",
          visible: true
        },
        {
          field: "leave",
          fieldName: "请假（人）",
          visible: true
        },
        {
          field: "travel",
          fieldName: "出差（人）",
          visible: true
        },
        {
          field: "outsidePerson",
          fieldName: "外出（人）",
          visible: true
        },
        {
          field: "overtime",
          fieldName: "加班（人）",
          visible: true
        },
        {
          field: "unnormalTotalPerson",
          fieldName: "考勤异常总人次",
          visible: true
        },
        {
          field: "noAttPerson",
          fieldName: "未打卡（人）",
          visible: true
        },
        {
          field: "latePerson",
          fieldName: "迟到（人）",
          visible: true
        },
        {
          field: "earlyPerson",
          fieldName: "早退（人）",
          visible: true
        },
        {
          field: "absentPerson",
          fieldName: "旷工（人）",
          visible: true
        },
        {
          field: "abnormalPerson",
          fieldName: "异常（人）",
          visible: true
        },
        {
          field: "lack",
          fieldName: "未打卡（次）",
          visible: true
        },
        {
          field: "late",
          fieldName: "迟到（次）",
          visible: true
        },
        {
          field: "early",
          fieldName: "早退（次）",
          visible: true
        },
        {
          field: "absent",
          fieldName: "旷工（次）",
          visible: true
        },
        {
          field: "abnormal",
          fieldName: "异常（次）",
          visible: true
        }
      ],
      // 部门 状态 对照表
      allstatus: {
        must: "0", // 应出勤（人）
        actual: "1", // 实出勤（人）
        leave: "14", // 请假（人次）10
        travel: "16", // 出差（人次）
        overtime: "9", // 加班（人次）
        late: "2", // 迟到（次）
        early: "3", // 早退（次）
        lack: "5", // 未打卡（次）
        outsidePerson: "15", // 外出（人）
        lackPerson: "17", // 缺勤（人）
        unnormalTotalPerson: "2,3,5,11,12,17", // 考勤异常总人数
        noAttPerson: "5", // 未打卡（人）
        latePerson: "2", // 迟到（人）
        absentPerson: "12", // 旷工（人）
        abnormalPerson: "11", // 异常（人）
        absent: "12", // 旷工（次）
        abnormal: "11", // 异常（次）
        earlyPerson: "3" // 早退（人）
      },
      // 人员状态 对照表
      allstatusPeople: {
        mustAtt: "0",
        actualAtt: "1",
        actualHourAtt: "1",
        salatyDayAtt: "1",
        salatyHourAtt: "1",
        overTime: "9", // 加班
        noAtts: "5", // 未打卡
        late: "2", // 迟到
        trip: "16", // 出差
        vac: "14", // 请假
        early: "3", // 早退
        noAtt: "17", // 缺勤
        unnormalTotalCount: "2,3,5,11,12,17", // 考勤异常总次数
        abnormal: "11", // 异常
        absent: "12", // 旷工
        leaveDays: "14", // 请假天
        leaveHours: "14", // 请假小时
        outsideCount: "15" // 外出
      },
      // 部门数据
      deptData: [],
      // 蒙层
      loading: true,
      // 汇总行数据
      summary: [
        {
          id: "lackPerson",
          status: "17",
          num: 0,
          name: "缺勤"
        },
        {
          id: "noAttPerson",
          status: "5",
          num: 0,
          name: "未打卡"
        },
        {
          id: "latePerson",
          status: "2",
          num: 0,
          name: "迟到"
        },
        {
          id: "earlyPerson",
          status: "3",
          num: 0,
          name: "早退"
        },
        {
          id: "absentPerson",
          status: "12",
          num: 0,
          name: "旷工"
        },
        {
          id: "abnormalPerson",
          status: "11",
          num: 0,
          name: "异常"
        },
        {
          id: "leave",
          status: "14",
          num: 0,
          name: "请假"
        },
        {
          id: "travel",
          status: "16",
          num: 0,
          name: "出差"
        },
        {
          id: "outsidePerson",
          status: "15",
          num: 0,
          name: "外出"
        },
        {
          id: "overtime",
          status: "9",
          num: 0,
          name: "加班"
        }
      ],
      // 出勤率
      attendance: "",
      // 是否显示 部门表格
      deptFlg: false,
      // 部门固定列
      deptfixedColumnNum: 0,
      // 4个图形的数据
      option1: {}, // 汇总
      option2: {}, // 部门 明细
      option3: {}, // 日期 明细
      option4: {}, // 人员 明细
      cell: 1, // 单元格点击的时候也会触发展开收起
      // 人员 表头
      peopleHeadList: [],
      peopleData: {},
      peoplefixedColumnNum: 0,
      peopleFlg: false,
      // 按工作日统计 0 按实际统计 1 默认是0
      countType: "0",
      attendDetailsFlg: false,
      // 穿透参数
      attendDetailsData: {},
      isFullScreen: false, // 是否全屏
      finish: false, // 全屏是否完成
      variableList: []
    };
  },
  computed: {
    shortcuts() {
      return this.variableList
        .filter((item) => item.id !== "0")
        .map((item) => ({
          text: item.name,
          onClick(picker) {
            const variableTime = Utils.getvariableTime(item);
            const end = new Date();
            const start = new Date();
            start.setTime(variableTime[0].getTime());
            end.setTime(variableTime[1].getTime());
            picker.$emit("pick", [start, end]);
          }
        }));
    }
  },
  mounted() {
    this.queryRbacGloableVariableList();
    // 默认查询条件
    this.getDay();
    // this.day = "2020-12-01,2020-12-31";
    this.getTree();
    // 获取 报表id
    const { templateId } = this.$route?.query || {};
    this.templateId = templateId;
    this.queryUserConfig(() => {
      // 查表头 查询部门数据
      this.queryUserBehavior();
      // 人员
      this.queryAttPersonDetailTitle();
    });
  },
  methods: {
    backToAttendance() {
      this.$router.back();
    },
    async queryRbacGloableVariableList() {
      this.variableList = (await hrService.queryRbacGloableVariableList({ type: 3 })) || [];
    },
    // 查询 按实际 还是 按 工作日
    async queryUserConfig(fn) {
      const p = { configKey: "attendanceReportCountType" };
      const data = (await hrService.queryUserConfig(p)) || { value: "0" };
      if (data !== "") {
        this.countType = data.value;
      }
      fn && fn();
    },
    // 获取查询 条件day的值
    getDay() {
      const s = dayJs(this.dateRange[0]).format("YYYY-MM-DD");
      const e = dayJs(this.dateRange[1]).format("YYYY-MM-DD");
      this.day = `${s},${e}`;
    },
    // 获取机构
    async getTree() {
      const p = {
        accessId: this.templateId
      };
      const res = await hrService.queryDeptTreeAll(p);
      this.tree = res;
    },
    // 是否隐藏
    getShow(hide) {
      this.hideMap[hide] = !this.hideMap[hide];
    },
    // 部门 下拉
    selectChange() {},
    // 查询按钮
    searchAction() {
      // 查部门
      this.deptId = JSON.parse(JSON.stringify(this.deptIdShow));
      this.getDay();
      // 查部门
      this.queryReportAttDept();
      // 查人员
      this.queryAttPersonDetail({
        pageNo: 1,
        pageSize: 10
      });
    },
    // 部门 日期 人员 点击切换
    modClick(str) {
      this.showType = str;
      // 默认是汇总图
      this.showType1 = true;
      this.repaint();
    },
    // 汇总 明细切换
    getshowType1() {
      this.showType1 = !this.showType1;
      this.repaint();
    },
    // 重绘图形
    repaint() {
      let option = {};
      let dom;
      let myChart;
      if (this.showType1) {
        dom = this.$refs.dom1;
        myChart = this.$charts.init(dom);
        option = this.option1;
      } else if (this.showType === "dept") {
        dom = this.$refs.dom2;
        myChart = this.$charts.init(dom);
        option = this.option2;
      } else if (this.showType === "date") {
        dom = this.$refs.dom3;
        myChart = this.$charts.init(dom);
        option = this.option3;
      } else if (this.showType === "people") {
        dom = this.$refs.dom4;
        myChart = this.$charts.init(dom);
        option = this.option4;
      }
      myChart.clear();
      myChart.setOption(option);
    },
    // 获取部门表头
    async queryUserBehavior() {
      const p = { templateId: `r${this.templateId}` };
      const res = (await hrService.queryUserBehavior(p)) || {};
      const show = []; // 显示字段的key
      const list = []; // 最终显示的list
      if (res.fieldSort.length) {
        // 获取 全部的显示字段
        res.fieldSort.forEach((element) => {
          element.visible = true;
          show.push(element.field);
          list.push(element);
        });
        this.deptHeadList.forEach((element) => {
          if (show.indexOf(element.field) === -1) {
            element.visible = false;
            list.push(element);
          }
        });
        this.deptHeadList = list;
        this.deptfixedColumnNum = Number(res.fixedColumnNum);
      }
      this.queryReportAttDept();
    },
    // 获取部门数据
    async queryReportAttDept() {
      this.loading = true;
      const p = {
        day: this.day,
        accessId: this.templateId,
        countType: this.countType
      };
      if (this.deptId.length) {
        p.deptId = this.deptId.join(",");
      }
      // 清空 汇总行数据
      this.summary.forEach((element) => {
        element.num = 0;
      });
      // 应出勤人数
      let must = 0;
      // 实出勤人数
      let actual = 0;
      const res = (await hrService.queryReportAttDept(p)) || [];
      res.forEach((element) => {
        element.open = false;
        if (element.hasChildren === "000") {
          element.hasNode = true;
          element.nodes = [];
        } else {
          element.hasNode = false;
        }
        // 应出勤人数 实出勤人数
        must += Number(element.must);
        actual += Number(element.actual);
      });
      this.deptData = res;
      // 计算汇总数据
      this.summary.forEach((element) => {
        res.forEach((element1) => {
          element.num += Number(element1[element.id]);
        });
      });
      this.hideMap.allData0 = false;
      this.summary.forEach((element) => {
        if (element.num > 0) {
          this.hideMap.allData0 = true;
        }
      });
      this.loading = false;
      this.deptFlg = true;
      // 处理 汇总图形
      this.chartDom1(must, actual);
      // 部门 明细图形
      this.chartDom2(res);
      // 日期 部门图形
      this.chartDom3("0");
    },
    // 汇总图形
    chartDom1(must, actual) {
      try {
        let a = (actual / must || 0) * 100;
        if (a === Infinity) {
          a = 0;
        }
        this.attendance = `${a.toFixed(2)}%`;
      } catch (error) {
        this.attendance = "0.00%";
      }
      const dom = this.$refs.dom1;
      const myChart = this.$charts.init(dom);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
          show: true,
          textStyle: {
            fontSize: "12"
          }
        },
        title: [
          {
            text: `{name|${this.attendance}}\n{val|出勤率}`,
            top: "center",
            left: "center",
            textStyle: {
              rich: {
                name: {
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "#033333",
                  padding: [10, 0]
                },
                val: {
                  fontSize: 12,
                  fontWeight: "normal",
                  color: "#033333"
                }
              }
            }
          }
        ],
        color: ["#5282E4", "#51B3F2"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                formatter: "{b}: {c}",
                textStyle: {
                  fontSize: "12",
                  color: "#333"
                }
              }
            },
            itemStyle: {
              normal: {
                // borderColor: "#FFFFFF"
                // borderWidth: 3
              }
            },
            labelLine: {
              normal: {
                show: true
              }
            },
            data: [
              { value: must, name: "应出勤人数" },
              { value: actual, name: "实出勤人数" }
            ]
          }
        ]
      };
      this.option1 = option;
      myChart.clear();
      myChart.setOption(option);
      // 穿透
      myChart.on("click", (param) => {
        if (param.name === "实出勤人数") {
          // 实出勤人数穿透
          this.throughFun(
            {
              status: "1"
            },
            ""
          );
        }
      });
    },
    // 部门 明细图形
    chartDom2(deplist) {
      const dom = this.$refs.dom2;
      const myChart2 = this.$charts.init(dom);

      const xName = []; // 柱状图横坐标数据  部门名称
      const xdata = [];
      deplist.forEach((element) => {
        xName.push(element.text);
        let d = ((Number(element.actual) / Number(element.must)) * 100).toFixed(2);
        if (isNaN(d) || d === Infinity) {
          d = 0;
        }
        xdata.push(d);
      });

      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            const data = dd[0] || {};
            res.push(data.name);
            res.push(`出勤率 : ${data.value}%`);
            return res.join("<br/>");
          }
        },
        calculable: true,
        color: [
          "#06cdc6",
          "#45b9ee",
          "#ff8463",
          "#4bd793",
          "#94bcd3",
          "#f79451",
          "#66dfe0",
          "#5de674",
          "#b6a2de",
          "#e1d8ad",
          "#60c0dd",
          "#c4ccd3",
          "#99cccc",
          "#a8f15f",
          "#fcce10",
          "#4ebca9",
          "#2ec7c9",
          "#cbd570",
          "#73b0ee",
          "#99a9cc"
        ],
        // legend: {
        //   x: "center",
        //   bottom: "20px"
        //   // itemGap:10,
        //   // data: ["机构1", "机构2", "机构3", "机构4"]
        // },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "80px",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            data: xName
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "出勤率(%)",
            max: 100,
            min: 0,
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            }
          }
        ],
        series: [
          {
            type: "bar",
            barMaxWidth: 28,
            // barGap:5,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: "{c}%"
              }
            },
            data: xdata,
            itemStyle: {
              normal: {
                color(params) {
                  return option.color[params.dataIndex];
                }
              }
            }
          }
        ]
      };
      this.option2 = option;
      myChart2.setOption(option);
    },
    // 获取数据
    async getchartDom2(id) {
      const p = {
        day: this.day,
        accessId: this.templateId,
        refDeptId: id,
        countType: this.countType
      };
      if (id === "0") {
        if (this.deptId.length) {
          p.deptId = this.deptId.join(",");
        }
      }

      const res = (await hrService.queryReportAttDept(p)) || [];
      // 图形
      this.chartDom2(res);
    },
    // 获取部门树表格 子集 不会改变汇总的值
    async handleTreeLoad(obj) {
      this.cell = 2;
      if (obj.row.hasChildren === "001") {
        return;
      }
      // open = true 展开ing
      obj.row.open = true;
      const p = {
        day: this.day,
        accessId: this.templateId,
        refDeptId: obj.row.id,
        countType: this.countType
      };
      // if (this.deptId.length) {
      //   p.deptId = this.deptId.join(",");
      // }
      const res = (await hrService.queryReportAttDept(p)) || [];
      res.forEach((element) => {
        if (element.hasChildren === "000") {
          element.hasNode = true;
          element.nodes = [];
        } else {
          element.hasNode = false;
        }
      });
      // 图形
      this.chartDom2(res);
      obj.row.nodes = res;
    },
    // 存部门头部 用户习惯
    handleMoreChange(params) {
      // 数组
      const arr = JSON.parse(params.fieldSort);
      const list = [];
      arr.forEach((element) => {
        if (element.type !== "index" && element.visible) {
          const obj = {
            area: "main",
            field: element.prop,
            nameVariable: element.prop,
            fieldName: element.label,
            visible: element.visible
          };
          list.push(obj);
        }
      });
      const p = {
        fixedColumnNum: params.fixedColumnNum,
        templateId: `r${this.templateId}`,
        fieldSort: JSON.stringify(list)
      };
      hrService.saveUserBehavior(p);
    },
    // 部门表格点击 穿透用的数据
    cellClickEvent(obj, flg) {
      if (this.cell === 2) {
        // 请求了 接口的展开
        this.chartDom3(obj.row.id);
        this.cell = 1;
        // 穿透 判断
      } else {
        // 已经请求过的
        this.cell = 1;
        // open = 1
        if (!obj.row.hasNode) {
          // 没有下一级 不需要展开 做穿透
        } else if (obj.row.open) {
          // 由开启到关闭
          // refDeptId = parentId; 参数就是parentId
          this.getchartDom2(obj.row.parentId);
          this.chartDom3(obj.row.parentId);
        } else {
          // 由关闭到开启 直接拿nodes里面数据渲染图形
          this.chartDom2(obj.row.nodes);
          this.chartDom3(obj.row.id);
        }
        obj.row.open = !obj.row.open;
      }
      this.throughFun(obj, flg);
    },
    // 日期类型 图表 折线图
    async chartDom3(id) {
      const p = {
        day: this.day,
        accessId: this.templateId,
        refDeptId: id,
        countType: this.countType
      };
      if (id === "0") {
        if (this.deptId.length) {
          p.deptId = this.deptId.join(",");
        }
      }
      const res = (await hrService.queryReportAttDay(p)) || [];
      const legendName = [];
      const xdata = []; // x轴
      // const myData = {};
      res.forEach((element) => {
        xdata.push(element.day.substring(5, 10)); // 横坐标时间//2017-09-18
        // const singleDept = {};
        const deplist = element.deptList || [];
        deplist.forEach((element1) => {
          if (legendName.indexOf(element1.text) < 0) {
            legendName.push(element1.text); // 部门名称
          }
        });
      });
      const seriesData = [];
      let yMin = 0;
      legendName.forEach((element) => {
        const obj = {
          name: element,
          type: "line",
          label: {
            normal: {
              show: true,
              position: "top",
              formatter: "{c}%"
            }
          }
        };
        const per = [];
        res.forEach((element1) => {
          const deplist = element1.deptList || [];
          deplist.forEach((element2) => {
            if (element2.text === element) {
              let c = Number((element2.actual / element2.must) * 100).toFixed(2);
              if (isNaN(c) || c === Infinity) {
                c = 0;
              }
              per.push(c);
            }
          });
        });
        obj.data = per;
        seriesData.push(obj);
      });

      const total = [];
      seriesData.forEach((element) => {
        element.data.forEach((element1) => {
          if (total.indexOf(element1) < 0) {
            total.push(element1);
          }
        });
      });
      if (total.indexOf(NaN) < 0) {
        const min = Math.min.apply(null, total);
        if (min < 10) {
          yMin = 0;
        } else {
          yMin = Math.floor(min / 10) * 10;
        }
      }
      const dom = this.$refs.dom3;
      const myChart3 = this.$charts.init(dom);

      const option3 = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
            dd.forEach((b) => {
              res.push(`${b.seriesName}：${b.value}%`);
            });
            return res.join("<br/>");
          }
        },
        /* color: ['#06cdc6', '#45b9ee', '#ff8463', '#4bd793',
                               '#94bcd3', '#f79451', '#66dfe0', '#5de674',
                               '#b6a2de', '#e1d8ad', '#60c0dd', '#c4ccd3',
                               '#99cccc', '#a8f15f', '#fcce10', '#4ebca9',
                               '#2ec7c9', '#cbd570', '#73b0ee', '#99a9cc',
                            ], */
        legend: {
          x: "center",
          icon: "roundRect",
          itemGap: 10,
          bottom: "20px",
          data: legendName
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "80px",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            data: xdata
          }
        ],
        yAxis: [
          {
            min: yMin,
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            name: "出勤率(%)",
            type: "value"
          }
        ],
        series: seriesData
      };
      this.option3 = option3;
      myChart3.clear();
      myChart3.setOption(option3);
    },
    // queryAttPersonDetailTitle 获取人员 表头
    async queryAttPersonDetailTitle() {
      const res = (await hrService.queryAttPersonDetailTitle()) || [];
      res.fields.forEach((element) => {
        element.fieldType = element.type;
        element.field = element.nameVariable;
        element.fieldName = element.name;
      });
      this.peopleHeadList = res.fields;
      this.queryUserBehaviorPeople();
    },
    // 人员 保存的用户习惯 表头
    async queryUserBehaviorPeople() {
      const p = { templateId: this.templateId };
      const res = (await hrService.queryUserBehavior(p)) || {};
      const show = []; // 显示字段的key
      const list = []; // 最终显示的list
      if (res.fieldSort.length) {
        // 获取 全部的显示字段
        res.fieldSort.forEach((element) => {
          element.visible = true;
          show.push(element.field);
          list.push(element);
        });
        this.peopleHeadList.forEach((element) => {
          if (show.indexOf(element.field) === -1) {
            element.visible = false;
            list.push(element);
          }
        });
        this.peopleHeadList = list;

        this.peoplefixedColumnNum = Number(res.fixedColumnNum);
      }
      // 部分 列不能搜索 排序
      this.peopleHeadList.forEach((element) => {
        if (element.nameVariable === "overTime" || element.dataType === "vac") {
          element.filter = false;
          element.sort = false;
        } else {
          element.filter = true;
          element.sort = true;
        }
      });
      this.queryAttPersonDetail({
        pageNo: 1,
        pageSize: 10
      });
    },
    // 人员表数据
    async queryAttPersonDetail(page) {
      this.peopleData.loading = true;
      const p = {
        day: this.day,
        accessId: this.templateId,
        countType: this.countType
      };
      if (this.deptId.length) {
        p.deptId = this.deptId.join(",");
      }
      // 合并 分页查询条件
      Object.assign(p, page);
      const res = (await hrService.queryAttPersonDetail(p)) || [];
      res.loading = false;
      this.peopleData = res;
      this.peopleFlg = true;
      this.chartDom4();
    },
    // 存人员头部 用户习惯
    handleMoreChangepeople(params) {
      // 数组
      const arr = JSON.parse(params.fieldSort);
      const list = [];
      arr.forEach((element) => {
        if (element.type !== "index" && element.visible) {
          const obj = {
            area: "main",
            field: element.prop,
            nameVariable: element.prop,
            fieldName: element.label,
            visible: element.visible,
            fieldType: element.data.fieldType,
            type: element.data.type
          };
          list.push(obj);
        }
      });
      const p = {
        fixedColumnNum: params.fixedColumnNum,
        templateId: this.templateId,
        fieldSort: JSON.stringify(list)
      };
      hrService.saveUserBehavior(p);
    },
    // 分页事件
    handlePageChanged(obj) {
      this.queryAttPersonDetail(obj);
    },
    // 人员搜索 排序
    handleTableFilterChange(obj) {
      const con = {
        page: 1,
        pageSize: 10,
        sortField: {}
      };
      const list = obj.$table.$refs.filterWrapper.filterStore.dataList;
      const filterFields = [];
      let sortField = {};
      list.forEach((element) => {
        if (element.type === "sort") {
          // 排序字段
          sortField = {
            area: "main",
            field: element.field,
            nameVariable: element.field,
            sortType: element.sortType
          };
        } else if (typeof element.value === "string") {
          // 搜索
          const o = {
            area: "main",
            field: element.field,
            nameVariable: element.field,
            value: element.value
          };
          filterFields.push(o);
        } else {
          const o = {
            area: "main",
            field: element.field,
            nameVariable: element.field,
            value: element.value.join("-")
          };
          filterFields.push(o);
        }
      });
      if (filterFields.length) {
        con.filterFields = JSON.stringify(filterFields);
      }
      con.sortField = JSON.stringify(sortField);
      this.queryAttPersonDetail(con);
    },
    // 人员图形
    chartDom4() {
      const dom = this.$refs.dom4;
      const myChart4 = this.$charts.init(dom);
      const xName = []; // 柱状图横坐标数据  部门名称
      const xdata = [];
      this.peopleData.records.forEach((element) => {
        xName.push(element.name);
        let d = Number((element.actualAtt / element.mustAtt) * 100).toFixed(2);
        if (isNaN(d) || d === Infinity) {
          d = 0;
        }
        xdata.push(d);
      });
      const option4 = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            const data = dd[0] || {};
            res.push(data.name);
            res.push(`出勤率 : ${data.value}%`);
            return res.join("<br/>");
          }
        },
        calculable: true,
        color: [
          "#06cdc6",
          "#45b9ee",
          "#ff8463",
          "#4bd793",
          "#94bcd3",
          "#f79451",
          "#66dfe0",
          "#5de674",
          "#b6a2de",
          "#e1d8ad",
          "#60c0dd",
          "#c4ccd3",
          "#99cccc",
          "#a8f15f",
          "#fcce10",
          "#4ebca9",
          "#2ec7c9",
          "#cbd570",
          "#73b0ee",
          "#99a9cc"
        ],
        // legend: {
        //   x: "center",
        //   bottom: "20px"
        //   // itemGap:10,
        //   // data: ["机构1", "机构2", "机构3", "机构4"]
        // },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "80px",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            axisLabel: {
              color: ["#999"],
              formatter(value) {
                return value.length > 4 ? `${value.slice(0, 4)}...` : value;
              }
            },
            data: xName
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "出勤率(%)",
            max: 100,
            min: 0,
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            }
          }
        ],
        series: [
          {
            type: "bar",
            barMaxWidth: 28,
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: "{c}%"
              }
            },
            data: xdata,
            itemStyle: {
              normal: {
                color(params) {
                  return option4.color[params.dataIndex];
                }
              }
            }
          }
        ]
      };
      this.option4 = option4;
      myChart4.clear();
      myChart4.setOption(option4);
    },
    // 按实际 按工作日的切换
    countTypeChange(str) {
      this.countType = str;
      const p = {
        configKey: "attendanceReportCountType",
        configValue: this.countType
      };
      hrService.saveUserConfig(p);
      // 查表头 查询部门数据
      this.queryUserBehavior();
      // 人员
      this.queryAttPersonDetailTitle();
    },
    // 穿透
    // 考勤明细
    // attendDetails
    throughFun(obj, flg) {
      let status = "";
      if (flg === "dept") {
        // 不能穿透的列
        if (obj.column.type === "index") {
          return;
        }
        if (["text", "name", "shiftName", "userNo"].indexOf(obj.column.field) > -1) {
          return;
        }
        status = this.allstatus[obj.column.field];
        this.attendDetailsData = {
          day: this.day,
          status,
          deptId: obj.row.id,
          countType: this.countType,
          type: 1
        };
      } else if (flg === "people") {
        // 不能穿透的列
        if (obj.column.type === "index") {
          return;
        }
        if (["text", "name", "shiftName", "userNo"].indexOf(obj.column.field) > -1) {
          return;
        }
        this.attendDetailsData = {
          day: this.day,
          userId: obj.row.id,
          countType: this.countType,
          type: 1
        };
        if (obj.column.field.indexOf("_") > -1) {
          // 请假
          status = "14";

          this.attendDetailsData.vacType = obj.column.field.split("_")[1];
        } else {
          status = this.allstatusPeople[obj.column.field];
        }
        this.attendDetailsData.status = status;
      } else {
        //
        const li = [];
        this.deptData.forEach((element) => {
          li.push(element.id);
        });
        status = obj.status;
        this.attendDetailsData = {
          day: this.day,
          status,
          deptIds: li.join(","),
          countType: this.countType,
          type: 1
        };
      }
      if (status === "0") {
        return;
      }
      this.attendDetailsFlg = true;
    },
    toShow() {
      this.attendDetailsFlg = false;
    },
    // 导出 接口未通
    async dataExport() {
      let res;
      if (this.showType === "people") {
        const exportData = this.deptData;
        // 导出人员数据
        const p = {
          exportData: JSON.stringify(exportData),
          day: this.day,
          accessId: this.templateId,
          countType: this.countType
        };
        res = await hrService.queryReportAttUserExport({ ...p, requestType: "stream" });
      } else {
        // 导出部门数据
        const exportData = this.deptData;
        const p = {
          exportData: JSON.stringify(exportData),
          day: this.day,
          accessId: this.templateId,
          countType: this.countType
        };
        res = await hrService.queryReportAttDeptExport({ ...p, requestType: "stream" });
      }
      const { headers } = res;
      const cd = headers["content-disposition"] || "";
      const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      // document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
    },
    /**
     * @description: 打开全屏
     * @param {type}
     * @return: isFullScreen = true;
     */
    openFull() {
      if (this.peopleData.loading && this.loading) {
        this.$message({
          message: "数据还在加载中请稍后",
          type: "warning"
        });
      } else {
        const elem = document.body;
        if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.requestFullScreen) {
          elem.requestFullscreen();
        } else {
          // 浏览器不支持全屏API或已被禁用
        }
        this.isFullScreen = true;
      }
    },
    /**
     * @description: 全屏组件渲染完成
     * @param {type}
     * @return:
     */
    finishFull() {
      this.finish = true;
      this.$nextTick(() => {
        const li = [1, 2, 3, 4];
        // 全屏处理展示的数据
        li.forEach((element) => {
          const dom = this.$refs[`fulldom${element}`];
          const myChart = this.$charts.init(dom);
          const option = JSON.parse(JSON.stringify(this[`option${element}`]));
          const fontColor = "#FFFFFF";
          if (element === 1) {
            option.series[0].label.normal.textStyle.color = fontColor;
            option.series[0].itemStyle.normal.borderColor = "#232c3d";
            option.title[0].textStyle.rich.name.color = "#FFFFFF";
            option.title[0].textStyle.rich.val.color = "#FFFFFF";
          } else {
            option.xAxis[0].axisLine.lineStyle.color = ["#E8ECF2"];
            if (option.xAxis[0].axisLabel) {
              option.xAxis[0].axisLabel.color = ["#E8ECF2"];
            }
            option.yAxis[0].axisLine.lineStyle.color = ["#E8ECF2"];
            if (option.yAxis[0].axisLabel) {
              option.yAxis[0].axisLabel.color = ["#E8ECF2"];
            }
            if (element === 3) {
              // 部门 折线
              option.tooltip.formatter = (dd) => {
                const res = [];
                res.push(dd[0].name);
                dd.forEach((b) => {
                  res.push(`${b.seriesName}：${b.value}%`);
                });
                return res.join("<br/>");
              };
              option.legend.textStyle = {
                color: "#E8ECF2"
              };
            } else if (element === 4) {
              // 人员
              option.series[0].itemStyle.normal.color = (params) => option.color[params.dataIndex];
              option.tooltip.formatter = (dd) => {
                const res = [];
                const data = dd[0] || {};
                res.push(data.name);
                res.push(`出勤率 : ${data.value}%`);
                return res.join("<br/>");
              };
              option.xAxis[0].axisLabel.formatter = (value) => (value.length > 4 ? `${value.slice(0, 4)}...` : value);
            } else if (element === 2) {
              // 部门 柱状
              option.series[0].itemStyle.normal.color = (params) => option.color[params.dataIndex];
              option.tooltip.formatter = (dd) => {
                const res = [];
                const data = dd[0] || {};
                res.push(data.name);
                res.push(`出勤率 : ${data.value}%`);
                return res.join("<br/>");
              };
            }
          }
          myChart.setOption(option);
        });
      });
    },
    /**
     * @description: 关闭全屏组件触发
     * @param {type}
     * @return: isFullScreen = false;
     */
    closeFull() {
      const elem = document;
      this.finish = false; // 全屏组件渲染v-if
      this.isFullScreen = false; // 是否全屏模式
      if (elem.webkitCancelFullScreen) {
        elem.webkitCancelFullScreen();
      } else if (elem.mozCancelFullScreen) {
        elem.mozCancelFullScreen();
      } else if (elem.cancelFullScreen) {
        elem.cancelFullScreen();
      } else if (elem.exitFullscreen) {
        elem.exitFullscreen();
      } else {
        // 浏览器不支持全屏API或已被禁用
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.reportAttend-box {
  height: 100%;
  width: 100%;
  background: #ffffff;
}
// top
.en-title-card {
  position: relative;
  padding-left: 40px;
  // 返回按钮
  .attendance-list-back-icon {
    cursor: pointer;
    color: #A9B5C6;
    position: absolute;
    left: 14px;
    top: 16px;
  }
  .attendance-list-right {
    min-width: 1075px;
    display: flex;
    position: relative;
  }
}
.en-title-card {
  padding-right: 0;
  &/deep/.en-title-card-right-container {
    height: 50px;
    padding: 0;
  }
  .attendance-list-right {
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .filter-item {
      background-color: #e8ecf2;

      box-shadow: 0px 2px 2px 0px rgba(236, 236, 236, 0.75);
      border-radius: 4px;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .label {
        line-height: 32px;
        font-size: 12px;
        color: #333333;
        margin-left: 20px;
        margin-right: 10px;
      }

      .content /deep/ {
        display: flex;
        align-items: center;
        .search-button {
          margin-left: 12px;
          margin-right: 20px;
        }
        .el-date-editor {
          padding: 0 10px;
          height: 32px;
          line-height: 32px;

          .el-range-separator {
            font-size: 12px;
          }

          .el-range-input {
            font-size: 12px;
          }
        }
      }
    }

    .attendance-header-item {
      margin-left: 20px;
      line-height: 32px;
      position: relative;
      padding-left: 24px;
      color: #333333;
      cursor: pointer;
      font-size: 12px;

      .en-icon {
        position: absolute;
        top: 8px;
        left: 0;
        width: 16px;
        height: 16px;
      }
    }
    .attendance-list-right-icon {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
    }
    .shu {
      display: inline-block;
      width: 1px;
      height: 24px;
      background: #dbe5eb;
      vertical-align: middle;
      // margin: 0 10px;
    }
  }
}
// 内容
.reportAttend-content {
  height: calc(100% - 51px);
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  .r-content-top {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .r-content-top-l,
    .r-content-top-r {
      display: flex;
      align-items: center;
    }
    .mod {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 70px;
      border: 1px solid #e8ecf2;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 20px;
      color: #636c78;
      span {
        margin-left: 5px;
      }
    }
    .mod.cur {
      color: #3e90fe;
      border: 1px solid #3e90fe;
    }
    .r-content-top-r {
      .right-btn {
        width: 30px;
        height: 30px;
        background: #179fdb;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        margin-right: 20px;
        .en-icon {
          margin-right: 0px;
        }
      }
      .en-icon {
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
}
// 图形
.r-content-chart {
  height: 425px;
  width: 100%;
  position: relative;
  .chart-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 425px;
    margin: 0 auto;
    z-index: 0;
    background: #ffffff;
  }
  .chart-box.cur {
    z-index: 1;
  }
  // 百分百
  .main-num {
    z-index: 1;
    position: absolute;
    left: 50%;
    width: 150px;
    margin-left: -75px;
    top: 45%;
    font-size: 12px;
    color: #333;
    text-align: center;
    margin-bottom: 0;
    p {
      margin: 0;
    }
    .num-txts {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #033333;
      margin-bottom: 12px;
    }
    .tits {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #033333;
    }
  }
}
// 汇总行
.r-content-summary {
  height: 76px;
  width: 100%;
  background: #f5f7fa;
  display: flex;
  padding: 20px 20px;
  margin-bottom: 10px;
  .col {
    flex: 1;
    color: #49bef2;
    text-align: center;
    font-size: 12px;
    font-family: PingFang;
    font-weight: bold;
    color: #3e90fe;
    p {
      margin: 0 0 10px 0;
    }
  }
}
// 表格
.r-content-table {
  width: 100%;
  position: relative;
  .table-box {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .table-box.cur {
    z-index: 1;
  }
  margin-bottom: 30px;
}
// 全屏设置样式
.full-box {
  width: 100%;
  height: 100%;
  position: relative;
  .full-title {
    position: relative;
    z-index: 100;
    height: 60px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    background: #232c3d;
    line-height: 60px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .full-center {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 60px;
    // background: #1D2532;

    .full-chart {
      width: 100%;
      height: 50%;
      display: flex;
      padding: 0 8px;
      margin-top: 8px;
      .chart-box {
        flex: 1;
        background: #232c3d;
        position: relative;
        .chart-box-center {
          width: 100%;
          height: 100%;
        }
        // 百分百
        .main-num {
          z-index: 1;
          position: absolute;
          left: 50%;
          width: 150px;
          margin-left: -75px;
          top: 45%;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          margin-bottom: 0;
          p {
            margin: 0;
          }
          .num-txts {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 12px;
          }
          .tits {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
body {
  // 避免产生留白
  background: #1d2532;
}
</style>
